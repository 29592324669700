import React from "react";

import { Box, Center, Heading, Text } from "@chakra-ui/react";

import { FontAlignType, FontWeightType, TextBlockTypes } from "../types";

import { renderWithHtml } from "../helpers/TextFormatters";

interface Props {
  text: string;
  variant?: TextBlockTypes;
  isSecondaryColor?: boolean;
  fontAlign?: FontAlignType;
  fontWeight?: FontWeightType;
  marginBottom?: string;
  lineHeight?: string;
  display?: string;
  maxWidth?: string;
  verticalAlign?: string;
}

const NewTextBlock: React.FC<Props> = ({
  text,
  variant = TextBlockTypes.DEFAULT,
  isSecondaryColor = false,
  fontAlign = "left",
  verticalAlign = "initial",
  fontWeight,
  lineHeight,
  display,
  maxWidth,
}) => {
  let renderedContent;

  const fontColor = isSecondaryColor ? "text.secondary" : "text.primary";

  switch (variant) {
    case TextBlockTypes.DEFAULT:
      renderedContent = (
        <Text
          fontSize="md"
          color={fontColor}
          // This weirdness is due to Chakra setting attributes to inherit
          // instead of using our theme styles if we pass undefined or null as the attribute
          // This could be fixed in later versions of Chakra
          {...(fontWeight ? { fontWeight: fontWeight } : undefined)}
          display="inline"
          whiteSpace="pre-line"
        >
          {renderWithHtml(text)}
        </Text>
      );
      break;
    case TextBlockTypes.TEXT_SM:
      renderedContent = (
        <Text
          fontSize="sm"
          color={fontColor}
          {...(fontWeight ? { fontWeight: fontWeight } : undefined)}
          display="inline"
          whiteSpace="pre-line"
        >
          {renderWithHtml(text)}
        </Text>
      );
      break;
    case TextBlockTypes.TEXT_LG:
      renderedContent = (
        <Text
          fontSize="lg"
          color={fontColor}
          {...(fontWeight ? { fontWeight: fontWeight } : undefined)}
          display="inline"
          whiteSpace="pre-line"
        >
          {renderWithHtml(text)}
        </Text>
      );
      break;
    case TextBlockTypes.TEXT_XL:
      renderedContent = (
        <Text
          fontSize="xl"
          color={fontColor}
          {...(fontWeight ? { fontWeight: fontWeight } : undefined)}
          display="inline"
          whiteSpace="pre-line"
        >
          {renderWithHtml(text)}
        </Text>
      );
      break;
    case TextBlockTypes.H1:
      renderedContent = (
        <Heading
          as="h1"
          size="headline"
          {...(fontWeight ? { fontWeight: fontWeight } : undefined)}
          color={fontColor}
        >
          {renderWithHtml(text)}
        </Heading>
      );
      break;
    case TextBlockTypes.H2:
      renderedContent = (
        <Heading
          as="h2"
          size="xl"
          {...(fontWeight ? { fontWeight: fontWeight } : undefined)}
          color={fontColor}
        >
          {renderWithHtml(text)}
        </Heading>
      );
      break;
    case TextBlockTypes.H3:
      renderedContent = (
        <Heading
          as="h3"
          size="lg"
          {...(fontWeight ? { fontWeight: fontWeight } : undefined)}
          color={fontColor}
        >
          {renderWithHtml(text)}
        </Heading>
      );
      break;
    case TextBlockTypes.H4:
      renderedContent = (
        <Heading
          as="h4"
          size="h4"
          {...(fontWeight ? { fontWeight: fontWeight } : undefined)}
          color={fontColor}
        >
          {renderWithHtml(text)}
        </Heading>
      );
      break;
    case TextBlockTypes.CAPTION:
      renderedContent = (
        <Heading
          size="caption"
          {...(fontWeight ? { fontWeight: fontWeight } : undefined)}
          color={fontColor}
        >
          {renderWithHtml(text)}
        </Heading>
      );
      break;
  }

  return (
    <Box
      display={display || "block"}
      lineHeight={lineHeight}
      textAlign={fontAlign}
      verticalAlign={verticalAlign}
    >
      {maxWidth ? (
        <Center margin="0 auto" w={maxWidth}>
          {renderedContent}
        </Center>
      ) : (
        renderedContent
      )}
    </Box>
  );
};

export default NewTextBlock;
