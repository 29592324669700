import { createContext } from "react";

import { NavObject, UserDetails } from "../types";

export enum NavigationMenuState {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  ERROR = "ERROR",
  READY = "READY",
}

interface Props {
  nav: NavObject | null;
  navigationMenuState: NavigationMenuState;
  userDetails: null | UserDetails;
}

export const NavigationMenuContext = createContext<Props>({
  nav: null,
  navigationMenuState: NavigationMenuState.INITIAL,
  userDetails: null,
});
