import React from "react";

import {
  Block,
  BlockType,
  ImageId,
  ModalStep,
  TextBlockTypes,
} from "../../types";

// This step view maps to our Expert Assist Detail Screen
export const ExpertAssistDetailStep = (): ModalStep => {
  const blocks: Block[] = [
    {
      id: "image",
      type: BlockType.IMAGE,
      imageId: ImageId.EXPERT_ASSIST_HERO,
    },
    {
      id: "title",
      type: BlockType.NEW_TEXT_BLOCK,
      text: "A team of caring experts, a couple clicks away",
      variant: TextBlockTypes.H4,
      fontWeight: "bold",
      fontAlign: "center",
    },
    {
      id: "benefits-list",
      type: BlockType.CHECKMARK_LIST_BLOCK,
      items: [
        {
          label: (
            <>
              Get access to{" "}
              <strong>unlimited live help from tax experts</strong> whenever you
              feel stuck.
            </>
          ),
        },
        {
          label: (
            <>
              Count on a <strong>final Expert Review of your return</strong> to
              make sure you're getting every dollar you're owed.
            </>
          ),
        },
      ],
      flexDir: "column",
      variant: "bullet",
    },
  ];

  return {
    blocks,
    buttonLabel: "Join Expert Assist for $89",
  };
};
