import React, { useState } from "react";

import { Box } from "@chakra-ui/react";

import { sendInputEnteredEvent, sendInputExitedEvent } from "../api";
import { Field, FieldType, FormObject, OpenHelpTypes } from "../types";

import BooleanFieldRadio from "./BooleanFieldRadio";
import BusinessDropdown from "./BusinessDropdown";
import CheckboxFieldInput from "./CheckboxFieldInput";
import CurrencyFieldInput from "./CurrencyFieldInput";
import DateFieldInput from "./DateFieldInput";
import DecimalFieldInput from "./DecimalFieldInput";
import DeviceIdFieldInput from "./DeviceIdFieldInput";
import EINFieldInput from "./EINFieldInput";
import HelpModal from "./HelpModal";
import IntegerFieldInput from "./IntegerFieldInput";
import PhoneFieldInput from "./PhoneFieldInput";
import SSNFieldInput from "./SSNFieldInput";
import SelectFieldDropdown from "./SelectFieldDropdown";
import SelectFieldRadio from "./SelectFieldRadio";
import StringFieldInput from "./StringFieldInput";

interface FieldComponentProps {
  field: Field;
  formObject: FormObject;
  isLoading: boolean;
}

function FieldComponent({ field, formObject, isLoading }: FieldComponentProps) {
  const { control, register, watch, setValue, errors } = formObject;

  switch (field.fieldType) {
    case FieldType.SELECT:
      return (
        <SelectFieldRadio
          key={field.id}
          field={field}
          error={errors && errors[field.id]}
          control={control}
          isLoading={isLoading}
        />
      );

    case FieldType.DROPDOWN:
      return (
        <SelectFieldDropdown
          key={field.id}
          field={field}
          register={register}
          error={errors && errors[field.id]}
          isLoading={isLoading}
        />
      );

    case FieldType.BUSINESS:
      return (
        <BusinessDropdown
          key={field.id}
          field={field}
          register={register}
          watch={watch}
          setValue={setValue}
          error={errors && errors[field.id]}
          isLoading={isLoading}
        />
      );

    case FieldType.CHECKBOX:
      return (
        <CheckboxFieldInput
          key={field.id}
          field={field}
          register={register}
          error={errors && errors[field.id]}
          control={control}
          isLoading={isLoading}
        />
      );

    case FieldType.STRING:
      return (
        <StringFieldInput
          key={field.id}
          field={field}
          register={register}
          error={errors && errors[field.id]}
          isLoading={isLoading}
        />
      );

    case FieldType.SSN:
      return (
        <SSNFieldInput
          key={field.id}
          field={field}
          error={errors && errors[field.id]}
          setValue={setValue}
          control={control}
          isLoading={isLoading}
        />
      );

    case FieldType.EIN:
      return (
        <EINFieldInput
          key={field.id}
          field={field}
          register={register}
          error={errors && errors[field.id]}
          control={control}
          isLoading={isLoading}
        />
      );
    case FieldType.PHONE:
      return (
        <PhoneFieldInput
          key={field.id}
          field={field}
          register={register}
          error={errors && errors[field.id]}
          control={control}
          isLoading={isLoading}
        />
      );

    case FieldType.DATE:
      return (
        <DateFieldInput
          key={field.id}
          field={field}
          register={register}
          error={errors && errors[field.id]}
          control={control}
          isLoading={isLoading}
        />
      );

    case FieldType.BOOLEAN:
      return (
        <BooleanFieldRadio
          key={field.id}
          register={register}
          field={field}
          error={errors && errors[field.id]}
          control={control}
          isLoading={isLoading}
        />
      );

    case FieldType.CURRENCY:
      return (
        <CurrencyFieldInput
          key={field.id}
          field={field}
          register={register}
          error={errors && errors[field.id]}
          control={control}
          isLoading={isLoading}
        />
      );

    case FieldType.INTEGER:
      return (
        <IntegerFieldInput
          key={field.id}
          field={field}
          register={register}
          error={errors && errors[field.id]}
          control={control}
          isLoading={isLoading}
        />
      );

    case FieldType.DECIMAL:
      return (
        <DecimalFieldInput
          key={field.id}
          field={field}
          register={register}
          error={errors && errors[field.id]}
          control={control}
          isLoading={isLoading}
        />
      );

    case FieldType.DEVICE_ID:
      return (
        <DeviceIdFieldInput
          key={field.id}
          field={field}
          register={register}
          isLoading={isLoading}
        />
      );

    default:
      return <></>;
  }
}

interface FieldRendererProps {
  field: Field;
  formObject: FormObject;
  screenId: string;
  isLoading: boolean;
}

const FieldRenderer: React.FC<FieldRendererProps> = ({
  field,
  formObject,
  screenId,
  isLoading,
}) => {
  const [focusStart, setFocusStart] = useState(0);
  return (
    // Include the data-field-id attribute for easier integration testing
    <Box
      display={field.fieldType == FieldType.DEVICE_ID ? "none" : "flex"}
      flexDirection="column"
      data-field-id={field.id}
      data-field-optional={field.optional}
      {...(field.redact || field.redact === undefined
        ? { "data-private": "redact" }
        : {})}
      onFocus={() => {
        setFocusStart(Date.now());
        sendInputEnteredEvent(screenId, field);
      }}
      onBlur={() =>
        sendInputExitedEvent(screenId, field, String(Date.now() - focusStart))
      }
    >
      <FieldComponent
        field={field}
        formObject={formObject}
        isLoading={isLoading}
      />
      {field.helpLabel && field.helpContentBlocks && (
        <HelpModal
          openEventProperties={{
            openKey: field.id,
            openType: OpenHelpTypes.FIELD,
            screenId: screenId,
          }}
          openText={field.helpLabel}
          helpContentBlocks={field.helpContentBlocks}
        />
      )}
    </Box>
  );
};

export default FieldRenderer;
