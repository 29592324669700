import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// Codespaces is a development-like environment, so the version of React that gets bundled is
// the dev version where StrictMode causes components to render twice. We would like to avoid
// the extra backend load caused by StrictMode evaluating effects twice, so we explicitly
// disable StrictMode here. In production builds, StrictMode is a no-op, so this is not needed.
if (
  process.env.REACT_APP_CODESPACE_NAME ||
  process.env.REACT_APP_DISABLE_STRICT_MODE == "true"
) {
  root.render(<App />);
} else {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
