import React, { LegacyRef, ReactNode } from "react";

import { Box, Flex } from "@chakra-ui/react";

interface ResponsiveContentWrapperProps {
  children: ReactNode;
  size?: "md" | "tablet";
  scrollTargetRef?: LegacyRef<HTMLDivElement>;
}

const ResponsiveContentWrapper: React.FC<ResponsiveContentWrapperProps> = ({
  children,
  size = "md",
  scrollTargetRef,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="appHeight"
      maxHeight={{ base: undefined, navmenu: "appHeight" }}
      overflowY={{ base: undefined, navmenu: "auto" }}
    >
      {/* ordinary div as a scrolling target */}
      {scrollTargetRef && (
        <div style={{ width: "100%", height: 0 }} ref={scrollTargetRef} />
      )}
      <Flex
        direction="column"
        alignItems="center"
        justifyContent={{ base: "flex-start", md: "flex-start" }}
        px={{ base: 0, md: 8, lg: 0 }}
        flex={{ base: 1, md: 0 }}
      >
        <Box
          display={{ base: "flex", md: "flex" }}
          flexDirection={"column"}
          justifyContent={{ md: "flex-start" }}
          m={0}
          mx={size === "md" ? { lg: 10 } : { lg: 10 }}
          w={{ base: "full", md: "full" }}
          maxWidth={{ md: size }}
          minHeight={{ base: "full", md: undefined }}
          height={{ base: "full", md: "auto" }}
          pt={{ base: 5, xs: 6, sm: 8, md: 12 }}
          px={
            size === "md"
              ? { base: 5, xs: 6, sm: 8, md: 0, lg: 0 }
              : { base: 5, xs: 6, sm: 8, md: 12, lg: 10 }
          }
          pb={{ base: 12, xs: 12, sm: 12, md: 12 }}
          borderRadius={{ base: undefined, md: "card" }}
          boxShadow={undefined}
          backgroundColor={{ base: "background.light" }}
          flex={{ base: 1, md: 0 }}
        >
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export default ResponsiveContentWrapper;
