import React from "react";

import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";

import {
  IconId,
  NavigationChoiceBlock,
  NavigationChoiceBlockDetailsItem,
  ReviewAndFileBlock,
  Status,
} from "../types";

import StatusPill from "../components/StatusPill";

import { detailsValuetoString } from "../helpers/TextFormatters";
import ResourceIcon from "./ResourceIcon";

const NavigationChoiceInfo = ({
  infoKey,
  infoValue,
}: {
  infoKey: string;
  infoValue: string;
}) => {
  return (
    <Text>
      {infoKey}: <b>{infoValue}</b>
    </Text>
  );
};

function renderNavigationChoiceDetailsItem(
  item: NavigationChoiceBlockDetailsItem,
) {
  const valueString = item.isCurrency
    ? detailsValuetoString(item.value)
    : item.value.toString();

  return (
    <>
      {item.label && item.label + " "}
      {valueString && (
        <Text as="b" fontWeight="semibold">
          {valueString}
        </Text>
      )}
    </>
  );
}

function DefaultNavigationChoiceItem({
  color,
  choice,
  navigationDisabled,
  isLoading,
  isDisabled,
}: {
  choice: NavigationChoiceBlock | ReviewAndFileBlock;
  color?: string;
  navigationDisabled?: boolean;
  isLoading: boolean;
  isDisabled: boolean;
}) {
  const { label, iconId, status, isEnabled, subLabel, details } = choice;

  const readyToRefile =
    "readyToRefile" in choice ? choice.readyToRefile : false;

  return (
    <>
      <Box
        data-test-block-type="navigation-choice"
        p={{ base: 6, md: 8 }}
        alignItems="center"
        justifyContent="space-between"
        background="white"
        rounded="card"
      >
        {/* TODO(marcia): Remove unnecessary link and switch
        integration test to using data-test-element-type */}
        <Link
          cursor={!navigationDisabled && isEnabled ? "pointer" : "default"}
          _hover={{ textDecoration: "unset" }}
        >
          <Flex flexDirection={{ base: "column", navmenu: "row" }} flex={1}>
            <Flex flexDirection={{ base: "column", navmenu: "row" }} flex={1}>
              {iconId && (
                <Box
                  w={12}
                  minW={12}
                  h={12}
                  borderRadius="full"
                  backgroundColor="background.dark"
                  display={{ base: "none", navmenu: "flex" }}
                  justifyContent="center"
                  alignItems="center"
                  mr={6}
                >
                  {isEnabled ? (
                    iconId && (
                      <ResourceIcon iconId={iconId} isEnabled={isEnabled} />
                    )
                  ) : (
                    <ResourceIcon iconId={IconId.LOCK_ICON} />
                  )}
                </Box>
              )}
              <Flex direction="column" justifyContent={"center"}>
                <Flex alignItems="center" alignContent="center">
                  <Box display={{ base: "flex", navmenu: "none" }}>
                    {isEnabled ? (
                      iconId && (
                        <Box mr="2">
                          <ResourceIcon iconId={iconId} isEnabled={isEnabled} />
                        </Box>
                      )
                    ) : (
                      <Box mr="2">
                        <ResourceIcon iconId={IconId.LOCK_ICON} />
                      </Box>
                    )}
                  </Box>

                  <Heading
                    alignItems="left"
                    color={isEnabled ? color : "text.secondary"}
                    width="100%"
                    size="lg"
                  >
                    {label}
                  </Heading>
                </Flex>

                {choice.info.length > 0 && (
                  <Box mt="2">
                    {choice.info.map((ch, idx) => {
                      return (
                        <NavigationChoiceInfo
                          key={idx}
                          infoKey={ch.key}
                          infoValue={ch.value}
                        />
                      );
                    })}
                  </Box>
                )}

                {/* TODO(marcia): When we have more complex unlocking behavior,
                  send the name of the upstream section from BE to render here */}
                {isEnabled ? (
                  subLabel &&
                  subLabel.length > 0 && (
                    <Text
                      align="left"
                      color="text.secondary"
                      mt={{ base: 4, navmenu: 2 }}
                      width="100%"
                      size="md"
                    >
                      {subLabel}
                    </Text>
                  )
                ) : (
                  <Text
                    color="text.secondary"
                    mt="4"
                    lineHeight={"22px"}
                    size="md"
                  >
                    Complete above to unlock this section.
                  </Text>
                )}

                {details &&
                  details.length > 0 &&
                  status &&
                  // Include the details if the section has been started or filled out
                  [
                    Status.STARTED,
                    Status.SUFFICIENT,
                    // TODO(Billy): can we simplify this to only use one set of statuses?
                    Status.ORANGE_IN_PROGRESS,
                    Status.GREY_EDIT,
                  ].includes(status.status) && (
                    <Box mt={{ base: 4, navmenu: 2 }}>
                      {details.map(
                        (
                          item: NavigationChoiceBlockDetailsItem,
                          index: number,
                        ) => (
                          <Box mt={1} key={index}>
                            {renderNavigationChoiceDetailsItem(item)}
                          </Box>
                        ),
                      )}
                    </Box>
                  )}
              </Flex>
            </Flex>
            <Box
              w={{ base: "full", navmenu: "160px" }}
              ml={{ base: undefined, navmenu: 8 }}
              mt={{ base: 4, navmenu: 0 }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              {/* Render the pill if the link is enabled and status is not null */}
              {isEnabled && status && (
                <StatusPill
                  status={status}
                  isLoading={isLoading}
                  isDisabled={isDisabled || navigationDisabled}
                  readyToRefile={readyToRefile}
                  marginTop={0}
                />
              )}
            </Box>
          </Flex>
        </Link>
      </Box>
    </>
  );
}

export default DefaultNavigationChoiceItem;
