import { useMemo } from "react";

import {
  Block,
  BlockType,
  ImageId,
  ModalStep,
  TextBlockTypes,
} from "../../types";

interface Props {
  expertAssistFee: number;
  taxFilingFee?: number;
}

// This step view maps to our Expert Assist Confirm Screen
export const ExpertAssistConfirmStep = ({
  expertAssistFee,
  taxFilingFee,
}: Props): ModalStep => {
  const rows = useMemo(
    () =>
      !taxFilingFee
        ? [
            {
              label: "Total due at filing",
              value: expertAssistFee,
            },
          ]
        : [
            {
              label: "Tax filing",
              value: taxFilingFee,
            },
            {
              label: "Expert Assist",
              value: expertAssistFee,
            },
            {
              label: "Total due at filing",
              value: taxFilingFee
                ? expertAssistFee + taxFilingFee
                : expertAssistFee,
            },
          ],
    [expertAssistFee, taxFilingFee],
  );

  const blocks: Block[] = [
    {
      id: "image",
      type: BlockType.IMAGE,
      imageId: ImageId.EXPERT_ASSIST_CONFIRM,
    },
    {
      id: "title",
      type: BlockType.NEW_TEXT_BLOCK,
      text: "Confirm your purchase of Expert Assist",
      variant: TextBlockTypes.H4,
      fontWeight: "bold",
      fontAlign: "center",
    },
    {
      id: "table",
      type: BlockType.TABLE,
      header: "Expert Assist",
      rows,
      formatAsDollar: true,
    },
  ];

  return {
    blocks,
    buttonLabel: "Confirm Purchase",
    onStepComplete: () => {
      // TODO: Enroll user in Expert Assist
      return true;
    },
  };
};
