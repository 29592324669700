import React from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

import { EstimatorStatus } from "../types";

import { dollarsToUsdNoCents } from "../helpers/CurrencyFormatter";
import DollarSignCircle from "../icons/DollarSignCircle";

const MAX_REFUND_DISPLAY_LABEL = "$999,999+";
const MAX_REFUND_DISPLAY_SIZE = 999999;

enum EstimateStyle {
  DEFAULT = "default",
  REFUND = "refund",
}

// TODO(marcia): Push colors and styles as variants into theme/index.ts
function getStyleByVariant(variant: EstimateStyle): {
  blockStyleProps: { bg: string; color: string };
  iconStyleProps: { color: string };
} {
  let blockStyleProps: { bg: string; color: string };
  let iconStyleProps: { color: string };

  if (variant === EstimateStyle.REFUND) {
    blockStyleProps = {
      bg: "success.light",
      color: "success.dark",
    };

    iconStyleProps = {
      color: "success.dark",
    };
  } else {
    blockStyleProps = {
      bg: "background.dark",
      color: "text.primary",
    };

    iconStyleProps = {
      color: "text.primary",
    };
  }

  return {
    blockStyleProps,
    iconStyleProps,
  };
}

function getMoneyDisplayLabel(amount: number): string {
  if (amount > MAX_REFUND_DISPLAY_SIZE) {
    return MAX_REFUND_DISPLAY_LABEL;
  } else {
    return dollarsToUsdNoCents(Math.abs(amount));
  }
}

function getEstimatorConfiguration(
  fedStatus: EstimatorStatus,
  fedAmount: number,
  stateAmount?: number,
): {
  fed: { text: string; label: string; style: EstimateStyle };
  state?: { text: string; label: string; style: EstimateStyle };
} {
  if (fedStatus === EstimatorStatus.EMPTY) {
    return {
      fed: {
        text: "",
        label: "As you work, you’ll \n see your estimated refund here.",
        style: EstimateStyle.DEFAULT,
      },
    };
  }
  if (fedStatus === EstimatorStatus.CALCULATING) {
    if (stateAmount !== undefined && stateAmount > 0) {
      return {
        fed: {
          text: "--",
          label: "Fed refund",
          style: EstimateStyle.REFUND,
        },
        state: {
          text: getMoneyDisplayLabel(Math.abs(stateAmount)),
          label: "State refund",
          style: EstimateStyle.REFUND,
        },
      };
    }
    return {
      fed: {
        text: "",
        label: "Keep going to \n see your estimated refund here",
        style: EstimateStyle.DEFAULT,
      },
    };
  }
  if (stateAmount !== undefined) {
    return {
      fed: {
        text: getMoneyDisplayLabel(Math.abs(fedAmount)),
        label: fedAmount >= 0 ? "Fed refund" : "Fed owed",
        style: fedAmount >= 0 ? EstimateStyle.REFUND : EstimateStyle.DEFAULT,
      },
      state: {
        text: getMoneyDisplayLabel(Math.abs(stateAmount)),
        label: stateAmount >= 0 ? "State refund" : "State owed",
        style: stateAmount >= 0 ? EstimateStyle.REFUND : EstimateStyle.DEFAULT,
      },
    };
  }
  return {
    fed: {
      text: getMoneyDisplayLabel(Math.abs(fedAmount)),
      label: fedAmount >= 0 ? "Estimated refund" : "Estimated taxes owed",
      style: fedAmount >= 0 ? EstimateStyle.REFUND : EstimateStyle.DEFAULT,
    },
  };
}

const EstimatorBlock = ({
  fedStatus,
  fedAmount,
  stateAmount,
}: {
  fedStatus: EstimatorStatus;
  fedAmount: number;
  stateAmount?: number;
}) => {
  const config = getEstimatorConfiguration(fedStatus, fedAmount, stateAmount);
  const { blockStyleProps: fedBlockStyleProps, iconStyleProps } =
    getStyleByVariant(config.fed.style);
  let boxGradient;
  const estimatorContents = [
    <div key="fed">
      <Text
        color={fedBlockStyleProps.color}
        fontSize="md"
        fontWeight="700"
        ml={{ base: "0", xs: "2" }}
      >
        {config.fed.text}
      </Text>
      <Text
        fontSize="xs"
        color="text.primary"
        ml={{ base: "0", xs: "2" }}
        whiteSpace="pre-wrap"
      >
        {config.fed.label}
      </Text>
    </div>,
  ];
  if (config.state) {
    const { blockStyleProps: stateBlockStyleProps } = getStyleByVariant(
      config.state.style,
    );
    // using chakras linear-gradient API https://chakra-ui.com/docs/styled-system/gradient
    // 0-50% is fed block background. 50-100% is state block bg.
    // background meets at a 110 angle
    boxGradient = `linear(100deg,${fedBlockStyleProps.bg} 50%,${stateBlockStyleProps.bg} 50%)`;
    estimatorContents.push(
      <div key="state">
        <Text
          color={stateBlockStyleProps.color}
          fontSize="md"
          fontWeight="700"
          ml="2"
        >
          {config.state.text}
        </Text>
        <Text fontSize="xs" color="text.primary" ml="2">
          {config.state.label}
        </Text>
      </div>,
    );
  }

  const defaultEstimatorWidth = config.state ? "400px" : "240px";
  const federalOnlyEstimatorWidth = "240px";

  return (
    <Flex
      alignItems="center"
      justifyContent="start"
      width={{
        base: config.state ? "200px" : federalOnlyEstimatorWidth,
        xs: config.state ? "225px" : federalOnlyEstimatorWidth,
        sm: config.state ? "250px" : federalOnlyEstimatorWidth,
        md: defaultEstimatorWidth,
      }}
      position="relative"
    >
      <Box position="absolute" display={{ base: "none", xs: "block" }}>
        <DollarSignCircle
          boxSize={10}
          stroke="#FFFFFF"
          fill="currentColor"
          strokeWidth={1.2}
          color={iconStyleProps.color}
        ></DollarSignCircle>
      </Box>

      <Flex
        py="0.5"
        borderRadius="xl"
        textAlign="center"
        background={fedBlockStyleProps.bg}
        width="100%"
        ml={{ base: "0", xs: "5" }}
        bgGradient={boxGradient}
        display="flex"
        justifyContent="space-around"
      >
        {estimatorContents}
      </Flex>
      {config.state && (
        <Box
          position="absolute"
          backgroundColor="white"
          width={{ base: "2px", xs: "5px", md: "8px" }}
          height="100%"
          transform="skew(170deg)"
          top="0"
          right={{
            base: "49%",
            xs: "44%",
            md: "47%",
          }}
        />
      )}
    </Flex>
  );
};

export default EstimatorBlock;
