import React, { useState } from "react";
import { Mail } from "react-feather";

import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Circle,
  HStack,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import { requestExpertReview, sendEventButtonClick } from "../api";
import { ButtonClickEventNames } from "../types";

import ExpertAssistHighFive from "../icons/ExpertAssist/expert_assist_high_five.svg";

type Props = {
  screenId: string;
  userEmail: string;
};

export const DesktopHandoffModalBody: React.FC<Props> = ({
  screenId,
  userEmail,
}) => {
  const [requestedExpertReview, setRequestedExpertReview] = useState(false);

  return (
    <Box pt={14}>
      <Image src={ExpertAssistHighFive} margin="auto" />
      <Heading size="lg" textAlign="center" mb={5} mt={8}>
        Get an Expert Review
      </Heading>

      <Text size="md" mb="5">
        Expert Reviews include recommendations for this year, plus tips for next
        year too.
      </Text>

      <Text size="md" mb="5">
        Please allow our experts up to 1 business day to send a thorough review
        of your return.
      </Text>
      <Text mb="5">
        <b>Your email:</b> {userEmail}
      </Text>
      {requestedExpertReview && (
        <Center mb={8}>
          <HStack spacing={2}>
            <Circle size={6} bg="success.medium" color="white">
              <CheckIcon boxSize={3} />
            </Circle>
            <Text color="success.dark">
              Your review will arrive within 1 business day
            </Text>
          </HStack>
        </Center>
      )}
      {!requestedExpertReview && (
        <Button
          onClick={() => {
            setRequestedExpertReview(true);
            // TODO, wire up to handler on the backend
            sendEventButtonClick({
              buttonName: ButtonClickEventNames.REQUEST_EXPERT_REVIEW,
              screenId: screenId,
            });

            requestExpertReview();
          }}
          width="full"
          variant="outline"
          mb="6"
          leftIcon={<Mail />}
        >
          Get an Expert Review
        </Button>
      )}
    </Box>
  );
};

export default DesktopHandoffModalBody;
