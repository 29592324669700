import React, { useEffect, useState } from "react";

import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import {
  ButtonClickCompletedAction,
  ButtonClickCompletedResult,
  ZendeskWidgetOpenedStatus,
  sendEventButtonClick,
  sendEventButtonClickCompleted,
  sendZendeskWidgetOpened,
} from "../api";
import {
  ActionType,
  ButtonClickEventNames,
  HandleSubmitType,
  NavigationChoiceType,
  Resource,
  ResourceType,
} from "../types";

import rollbar from "../../rollbar-utils";
import { openZendesk } from "../../zendesk";
import QuestionIcon from "../icons/QuestionIcon";
import QuestionIconOutlined from "../icons/QuestionIconOutlined";
import ExpertAssistCallScheduleModalBody from "./ExpertAssistCallScheduleModalBody";

interface SupportHeaderProps {
  screenId: string;
  onSubmit: HandleSubmitType | undefined;
  resource?: Resource;
  enrolledInExpertAssist: boolean;
}

const SupportHeader = ({
  screenId,
  onSubmit,
  resource,
  enrolledInExpertAssist,
}: SupportHeaderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // STOPLAUNCH(marcia): This screen id should come from the BE, since it
  // will change with each tax year.
  // TODO(marcia): After https://github.com/column-tax/column-be/pull/25047 is deployed,
  // we could switch over to using *only* landingScreenIdPermissive.
  const landingScreenId = "TY24::Screens::ExpertAssistScreen";
  const landingScreenIdPermissive = "Screens::ExpertAssistScreen";
  const fallbackScreenId = "Screens::FallbackLoadingScreen";

  const [filledIcon, setFilledIcon] = useState<boolean>(false);

  useEffect(() => {
    setFilledIcon(screenId.includes(landingScreenIdPermissive));
  }, [screenId]);

  const handleClick = () => {
    sendEventButtonClick({
      buttonName: ButtonClickEventNames.NAV_BAR_QUESTION_MARK,
      screenId: screenId,
      expertAssistEnrolled: enrolledInExpertAssist,
    });
    let action,
      result = ButtonClickCompletedResult.FAILURE;
    try {
      if (enrolledInExpertAssist) {
        if (screenId.includes(fallbackScreenId)) {
          onOpen();
          result = ButtonClickCompletedResult.SUCCESS;
          action = ButtonClickCompletedAction.OPENED_EXPERT_ASSIST_MODAL;
        } else if (!screenId.includes(landingScreenIdPermissive) && onSubmit) {
          // Check if we are on the landing screen. If we are not already
          // there, navigate to it!
          onSubmit({
            navigationChoice: landingScreenId,
            navigationChoiceType: NavigationChoiceType.Screen,
            type: ActionType.NAVIGATE,
            resource: resource,
            resourceType: ResourceType.Target,
          });
          result = ButtonClickCompletedResult.SUCCESS;
          action = ButtonClickCompletedAction.NAVIGATED_TO_LANDING_SCREEN;
        }
      } else {
        const timeout = setTimeout(() => {
          if (!document.querySelector(`iframe[title="Messaging window"]`)) {
            sendZendeskWidgetOpened({
              status: ZendeskWidgetOpenedStatus.FAILED,
            });
          }
        }, 4000);

        const callback = () => {
          clearTimeout(timeout);
          sendZendeskWidgetOpened({
            status: ZendeskWidgetOpenedStatus.SUCCEEDED,
          });
        };

        openZendesk(callback);
        result = ButtonClickCompletedResult.SUCCESS;
        action = ButtonClickCompletedAction.OPENED_ZENDESK_WIDGET;
      }
    } catch (e) {
      rollbar.error(`Error in SupportHeader handleClick: ${e}`);
    } finally {
      sendEventButtonClickCompleted({
        buttonName: ButtonClickEventNames.NAV_BAR_QUESTION_MARK,
        result,
        action,
        screenId,
      });
    }
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />

        <ModalContent
          maxH="calc(100% - 24px)" // 24px boundary from https://linear.app/column-tax/issue/COL-6060#comment-91353658
        >
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 0 }}>
            <Flex
              direction="column"
              height="full"
              justifyContent="space-between"
            >
              <ExpertAssistCallScheduleModalBody />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <IconButton
        key={screenId}
        display="inline-flex"
        w={12}
        h={12}
        background="none"
        variant="unstyled"
        aria-label="support"
        icon={filledIcon ? <QuestionIcon /> : <QuestionIconOutlined />}
        onClick={handleClick}
        _active={{ color: "border.medium" }}
        css
      />
    </>
  );
};

export default SupportHeader;
