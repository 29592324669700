import React from "react";

import { Button, Text } from "@chakra-ui/react";

import { sendEventButtonClick } from "../api";
import { Resource } from "../types";

import ExpertAssistEntrypointCard from "./ExpertAssistEntrypointCard";

interface Props {
  onSubmit: (screenId: string, resource?: Resource) => void;
  screenId: string;
  description?: string;
  buttonLabel?: string;
}

const ExpertAssistEntrypointBlock: React.FC<Props> = ({
  onSubmit,
  screenId,
  description = "Get live help now or schedule a call with an expert.",
  buttonLabel = "Talk to an Expert",
}) => {
  const handleClick = () => {
    // STOPLAUNCH(nihar): This screen id should come from the BE, and we should handle it however we handle the same navigation in SupportHeader.tsx
    // Perhaps it reads from the same place.
    const hardcodedScreenId = "TY24::Screens::ExpertAssistScreen";
    // TODO(marcia): After https://github.com/column-tax/column-be/pull/25047 is deployed,
    // we could remove the TY24 prefix here.
    // const hardcodedScreenId = "Screens::ExpertAssistScreen";
    sendEventButtonClick({
      buttonName: "ExpertAssistEntrypointBlock",
      screenId: screenId,
    });
    onSubmit(hardcodedScreenId);
  };

  return (
    <ExpertAssistEntrypointCard screenId={screenId}>
      <Text>{description}</Text>
      <Button
        variant="outline"
        size="md"
        onClick={handleClick}
        width={{ base: "100%", navmenu: "auto" }}
        px={6}
        py={3}
        flexShrink={0}
      >
        {buttonLabel}
      </Button>
    </ExpertAssistEntrypointCard>
  );
};

export default ExpertAssistEntrypointBlock;
