import React from "react";

import { Button, Text } from "@chakra-ui/react";

import { OpenHelpTypes } from "../types";

import ExpertAssistEntrypointCard from "./ExpertAssistEntrypointCard";
import ExpertReviewModalBody from "./ExpertReviewModalBody";
import Modal from "./Modal";

interface Props {
  screenId: string;
  description?: string;
  buttonLabel?: string;
  userEmail: string;
}

const ExpertReviewBlock: React.FC<Props> = ({
  screenId,
  description = "Get live help now or schedule a call with an expert.",
  buttonLabel = "Talk to an Expert",
  userEmail,
}) => {
  const openContent = (
    <Button
      variant="outline"
      size="md"
      width="full"
      px={6}
      py={3}
      flexShrink={0}
    >
      {buttonLabel}
    </Button>
  );

  const openEventProperties = {
    openKey: "desktop_handoff",
    openType: OpenHelpTypes.BLOCK,
    screenId: screenId,
  };
  return (
    <ExpertAssistEntrypointCard screenId={screenId}>
      <Text>{description}</Text>
      <Modal
        openEventProperties={openEventProperties}
        openText={openContent}
        fullWidth={true}
        body={
          <ExpertReviewModalBody screenId={screenId} userEmail={userEmail} />
        }
      />
    </ExpertAssistEntrypointCard>
  );
};

export default ExpertReviewBlock;
