import React, { useMemo } from "react";

import { Box, useDisclosure } from "@chakra-ui/react";

import { sendEventButtonClick } from "../api";
import {
  Block,
  BlockType,
  ButtonClickEventNames,
  IconId,
  ImageId,
  Screen,
  ScreenId,
  Status,
  TextBlockTypes,
} from "../types";

import { openLink } from "../../fileDownloadUtils";
import { convertToExternalBrowserUrl } from "../../utils/nav-utils";
import { openZendesk } from "../../zendesk";
import NavigationChoice from "./NavigationChoice";
import ModalBlock from "./blocks/ModalBlock";

interface Props {
  screen: Screen;
  screenId: string;
  name: string;
  phone: string;
}

const ExpertAssistLandingBlock: React.FC<Props> = ({
  screen,
  screenId,
  name,
  phone,
}) => {
  const {
    isOpen: isCallbackModalOpen,
    onOpen: onCallbackModalOpen,
    onClose: onCallbackModalClose,
  } = useDisclosure();

  const callExpertClick = () => {
    sendEventButtonClick({
      buttonName: ButtonClickEventNames.EXPERT_ASSIST_CALL_NOW,
      screenId: screenId,
    });
    onCallbackModalOpen();
  };

  const scheduleCallClick = () => {
    sendEventButtonClick({
      buttonName: ButtonClickEventNames.EXPERT_ASSIST_SCHEDULE,
      screenId: screenId,
    });
    const calendly = "https://calendly.com/lori-30min/taxpayer-support-session";
    openLink(convertToExternalBrowserUrl(calendly));
  };

  const chatWithBotClick = () => {
    sendEventButtonClick({
      buttonName: ButtonClickEventNames.EXPERT_ASSIST_CHAT,
      screenId: screenId,
    });
    openZendesk();
  };

  const assistOptions = [
    // STOPLAUNCH(marcia): Update these three icons to match figma:
    // https://www.figma.com/design/zBFDlq79xtJtBjq03qigWk/DIWM?node-id=775-8088&node-type=CANVAS&t=IckrkpVC02fFW3uH-0
    {
      label: "Talk to an expert",
      subLabel:
        "We’re available to take your call Monday - Friday, 10am - 5pm ET.",
      iconId: IconId.PHONE,
      buttonLabel: "Request Callback",
      onClick: callExpertClick,
    },
    {
      label: "Schedule a call for later",
      subLabel:
        "Schedule a call with one of our experts at a time that works best for you. ",
      iconId: IconId.CALENDAR,
      buttonLabel: "Schedule Call",
      onClick: scheduleCallClick,
    },
    {
      label: "Chat with support",
      subLabel:
        "Chat with our support team, starting with our expert-trained chatbot, Fini.",
      iconId: IconId.MESSAGE_SQUARE,
      buttonLabel: "Start Chat",
      onClick: chatWithBotClick,
    },
  ];

  const callbackModalContents: Block[] = useMemo(
    () => [
      {
        id: "callback-image",
        type: BlockType.IMAGE,
        imageId: ImageId.MILESTONE_INITIAL,
      },
      {
        id: "callback-title",
        type: BlockType.NEW_TEXT_BLOCK,
        text: "Talk to an expert",
        variant: TextBlockTypes.H3,
        fontAlign: "center",
      },
      {
        id: "callback-subtitle",
        type: BlockType.NEW_TEXT_BLOCK,
        text: "Confirm your information to request a callback.",
        variant: TextBlockTypes.DEFAULT,
        fontAlign: "center",
      },
      {
        id: "callback-form",
        type: BlockType.CALLBACK_FORM_BLOCK,
        name,
        phone,
        screenId,
      },
    ],
    [name],
  );

  return (
    <Box display="flex" gap={6} flexDirection="column" width="full">
      {isCallbackModalOpen && (
        <ModalBlock
          contents={callbackModalContents}
          screen={screen}
          showDismissModalButton={false}
          onModalClose={onCallbackModalClose}
        />
      )}
      {assistOptions.map((option) => {
        // STOPLAUNCH(marcia): Decide on a key that will be more stable than the text label
        const key = option.label;
        return (
          // STOPLAUNCH(marcia): This is a lot of re-purposing of our navigation choice code that
          // I do not fully understand yet! I think we could deprecate some of these fields.
          // STOPLAUNCH(marcia): Hnadle the loading spinner when these are clicked

          // STOPLANCH(marcia): Wire these clicks up to do the real thing
          // See https://linear.app/column-tax/issue/COL-24194/connect-click-targets-on-expert-assist-screen-to-their-destinations

          <NavigationChoice
            key={option.label}
            navigationDisabled={false}
            showLoadingSpinner={false}
            choice={{
              id: key,
              iconId: option.iconId,
              label: option.label,
              subLabel: option.subLabel,
              status: {
                label: option.buttonLabel,
                status: Status.CUSTOM_EXPERT_ASSIST,
                color: "unused",
                backgroundColor: "unused",
              },
              type: BlockType.NAVIGATION_CHOICE,
              screenId: ScreenId.UNUSED,
              isEnabled: true,
              info: [],
              recommended: true,
            }}
            onClick={option.onClick}
          />
        );
      })}
    </Box>
  );
};

export default ExpertAssistLandingBlock;
